const app = {
  projectId: "DUsSGBVXt09riaRPY1Jl",
  showHeader: true,
  showHeaderTitle: false,
  showHeaderNewChat: true,
  showContributionModal: false,
  headerTitle: false,
  headerTitleHref: false,
  disableDarkMode: true,
  backgroundColorLight: "#ffffff",
  backgroundColorDark: "#ffffff",
  chatMessageTextColorLight: "#234C42",
  chatMessageTextColorDark: "#234C42", // TODO: not implemented yet
  assistantName: "Najm",
  assistantInitials: "N",
  assistantAvatarBackgroundColorLight: "#0052A2",
  assistantAvatarBackgroundColorDark: "#0052A2", // TODO: not implemented yet
  assistantAvatarTextColorLight: "#FFFFFF",
  assistantAvatarTextColorDark: "#FFFFFF", // TODO: not implemented yet
  assistantLogo: false,
  userName: "You",
  userAvatarBackgroundColorLight: "#ADFFE1",
  userAvatarBackgroundColorDark: "#ADFFE1", // TODO: not implemented yet
  userAvatarTextColorLight: "#000000",
  userAvatarTextColorDark: "#000000", // TODO: not implemented yet
  messageInputPlaceholder: "Ask about expanding business to Saudi Arabia",
  chatDisclaimer: "Najm can make mistakes. Consider checking important information.",
  chatSuggestions: [
    {
      id: 1,
      question: "How difficult is it to set up a company in Saudi?",
    },
    {
      id: 2,
      question: "Why should I consider Saudi for expanding my business?",
    },
    {
      id: 3,
      question: "What are the key criteria for a company set up?",
    },
    {
      id: 4,
      question: "Is expansion to Saudi suitable for startups?",
    },
  ],
  chatHidePoweredByAskbot: true,
}

const OneUI = {
  Button: {
    pt: {
      root: {
        propsStateBased: {
          variant: {
            primary: {
              kind: {
                solid: app.disableDarkMode
                  ? "bg-neutral-800 context-dark:bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-900 text-white dark:bg-neutral-800 dark:text-white dark:hover:bg-neutral-600 dark:active:bg-neutral-900"
                  : "bg-neutral-800 context-dark:bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-900 text-white dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-300 dark:active:bg-neutral-500",
                plain: app.disableDarkMode
                  ? "text-neutral-900 dark:text-neutral-900 hover:text-neutral-400 active:text-neutral-900 dark:hover:text-neutral-400 dark:active:text-neutral-900"
                  : "text-neutral-900 dark:text-neutral-50 hover:text-neutral-400 active:text-neutral-900 dark:hover:text-neutral-500 dark:active:text-neutral-700",
              },
            },
          },
        },
      },
    },
  },
  Input: {
    pt: {
      container: {
        _common: app.disableDarkMode
          ? "border flex flex-row overflow-hidden bg-neutral-50 dark:bg-neutral-50"
          : "border flex flex-row overflow-hidden bg-neutral-50 dark:bg-neutral-900",
        propsStateBased: {
          focused: {
            true: {
              _common: "shadow-[0px_0px_0px_1px]",
              status: {
                false: "border-neutral-400 shadow-neutral-400",
                success: "border-neutral-400 shadow-neutral-400",
                error: "border-critical-500 shadow-critical-500",
                progress: "border-neutral-400 shadow-neutral-400",
              },
            },
            false: {
              status: {
                false: "border-neutral-300 dark:border-neutral-300",
                success: "border-neutral-300 dark:border-neutral-300",
                error: "border-critical-500",
                progress: "border-neutral-300 dark:border-neutral-300",
              },
            },
          },
        },
      },
      inputField: {
        _common:
          "flex flex-row items-center justify-start absolute inset-0 text-neutral-900 dark:text-neutral-900 my-3.5",
      },
      inputFieldWithPlaceholder: {
        _common: "relative h-full min-w-[10rem] overflow-x-hidden",
      },
      placeholder: {
        _common:
          "flex flex-row items-center justify-start absolute inset-0 font-normal text-neutral-300 dark:text-neutral-300 pointer-events-none font-element whitespace-nowrap text-sm sm:text-base",
      },
    },
  },
}

export {app, OneUI}
