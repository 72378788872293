<script setup>
import {onMounted, computed, ref, provide, watch} from "vue"
import {useRoute} from "vue-router"
import {app as astrolabsApp, OneUI as astrolabsUI} from "./config/astrolabs.js"
import {app as askfounderApp, OneUI as askfounderUI} from "./config/askfounder.js"

const route = useRoute()

const config = ref({})
const currentBackgroundColor = ref("")
const loadedRoute = ref(false)
const loadedConfig = computed(() => Object.keys(config.value).length > 0)
const ready = computed(() => loadedRoute.value && loadedConfig.value)

// FIXME: load askfounder config - couldn't figure how to provide() depending on project
provide("one-ui-config", astrolabsUI)

onMounted(() => {
  // Detect user's preferred color scheme
  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches

  // Apply the corresponding background color
  currentBackgroundColor.value = prefersDark
    ? config.value.backgroundColorDark
    : config.value.backgroundColorLight

  // Apply the background color to the HTML element
  document.documentElement.style.backgroundColor = currentBackgroundColor.value

  // Listen for changes in the color scheme and update the background color accordingly
  window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (event) => {
    currentBackgroundColor.value = event.matches
      ? config.value.backgroundColorDark
      : config.value.backgroundColorLight
    document.documentElement.style.backgroundColor = currentBackgroundColor.value
  })
})

watch(
  () => route.params.projectName,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      console.log(newVal)

      // TODO: store & get this from database
      if (newVal === "astrolabs") {
        config.value = astrolabsApp
        // provide("one-ui-config", astrolabsUI)
      } else if (newVal === "askfounder") {
        config.value = askfounderApp
        // provide("one-ui-config", askfounderUI)
      }
      loadedRoute.value = true

      // framework.one UI component overrides
    }
  }
)
</script>

<template>
  <div class="">
    <RouterView :config="config" v-if="ready" />
  </div>
</template>
