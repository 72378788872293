import MainView from "../views/MainView.vue"

export const routes = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/:projectName",
    name: "ProjectView",
    component: () => import("../views/ProjectView.vue"),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "chat",
        name: "ChatView",
        component: () => import("../views/ChatView.vue"),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },

  {path: "", redirect: {name: "ChatView"}},
  {path: "/:pathMatch(.*)*", redirect: {name: "ChatView"}},
]
