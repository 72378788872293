import {createApp} from "vue"
import {router} from "./router"
import App from "./App.vue"
import {createPinia} from "pinia"
import {attachFirestore} from "./plugins/attachFirestore.js"
import {resetState} from "./plugins/resetState.js"
import globalComponents from "./plugins/globalComponents.js"
import visible from "./plugins/visible.js"
import viewportListeners from "./plugins/viewportListeners"
// import sentry from "./plugins/sentry"
import "./style.less"
// import "firebaseui/dist/firebaseui.css"
// import "./assets/firebaseui-override.less"

const urlParams = new URLSearchParams(window.location.search)

// disable ga_tracking on this machine
const disableTracking = urlParams.get("disableTracking")
if (disableTracking) {
  const config = {ga_tracking: false}
  localStorage.setItem("@askbot/config", JSON.stringify(config))
}

window.app = {
  version: "__VERSION__",
  build: "__BUILD__",
  commit: "__RELEASE_SHA__",
  date: "__BUILD_DATE__",
}

const pinia = createPinia()
pinia.use(attachFirestore)
pinia.use(resetState)

const app = createApp(App)

// app.use(sentry)
app.use(globalComponents)
app.use(router)
app.use(pinia)
app.use(visible)
app.use(viewportListeners)

app.mount("#app")
