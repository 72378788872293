const app = {
  projectId: "BVhuh9c3bLTr6ypbi1hC",
  showHeader: true,
  showHeaderTitle: true,
  showHeaderNewChat: true,
  showContributionModal: false,
  headerTitle: "askfounder.ae",
  headerTitleHref: "https://askfounder.ae",
  disableDarkMode: true,
  backgroundColorLight: "#ffffff",
  backgroundColorDark: "#ffffff",
  chatMessageTextColorLight: "#171717",
  chatMessageTextColorDark: "#171717", // TODO: not implemented yet
  assistantName: "Buddy",
  assistantInitials: "B",
  assistantAvatarBackgroundColorLight: "#a5ff00",
  assistantAvatarBackgroundColorDark: "#a5ff00", // TODO: not implemented yet
  assistantAvatarTextColorLight: "#171717",
  assistantAvatarTextColorDark: "#171717", // TODO: not implemented yet
  assistantLogo: false,
  userName: "You",
  userAvatarBackgroundColorLight: "#1d4ed8",
  userAvatarBackgroundColorDark: "#1d4ed8", // TODO: not implemented yet
  userAvatarTextColorLight: "#171717",
  userAvatarTextColorDark: "#171717", // TODO: not implemented yet
  messageInputPlaceholder: "Ask about Dubai founder & startup scene",
  chatDisclaimer: "Buddy can make mistakes. Consider checking important information.",
  chatSuggestions: [
    {
      id: 1,
      question: "Do I have to pay taxes in the UAE?",
    },
    {
      id: 2,
      question: "What are the best incubators for tech startups in the UAE?",
    },
    {
      id: 3,
      question: "What is the difference between a freezone and mainland company?",
    },
    {
      id: 4,
      question: "Can I open business bank account on my own?",
    },
  ],
  chatHidePoweredByAskbot: true,
}

const OneUI = {
  Button: {
    pt: {
      root: {
        propsStateBased: {
          variant: {
            primary: {
              kind: {
                solid: app.disableDarkMode
                  ? "bg-neutral-800 context-dark:bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-900 text-white dark:bg-neutral-800 dark:text-white dark:hover:bg-neutral-600 dark:active:bg-neutral-900"
                  : "bg-neutral-800 context-dark:bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-900 text-white dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-300 dark:active:bg-neutral-500",
                plain: app.disableDarkMode
                  ? "text-neutral-900 dark:text-neutral-900 hover:text-neutral-400 active:text-neutral-900 dark:hover:text-neutral-400 dark:active:text-neutral-900"
                  : "text-neutral-900 dark:text-neutral-50 hover:text-neutral-400 active:text-neutral-900 dark:hover:text-neutral-500 dark:active:text-neutral-700",
              },
            },
          },
        },
      },
    },
  },
  Input: {
    pt: {
      container: {
        _common: app.disableDarkMode
          ? "border flex flex-row overflow-hidden bg-neutral-50 dark:bg-neutral-50"
          : "border flex flex-row overflow-hidden bg-neutral-50 dark:bg-neutral-900",
        propsStateBased: {
          focused: {
            true: {
              _common: "shadow-[0px_0px_0px_1px]",
              status: {
                false: "border-neutral-400 shadow-neutral-400",
                success: "border-neutral-400 shadow-neutral-400",
                error: "border-critical-500 shadow-critical-500",
                progress: "border-neutral-400 shadow-neutral-400",
              },
            },
            false: {
              status: {
                false: "border-neutral-300 dark:border-neutral-300",
                success: "border-neutral-300 dark:border-neutral-300",
                error: "border-critical-500",
                progress: "border-neutral-300 dark:border-neutral-300",
              },
            },
          },
        },
      },
      inputField: {
        _common:
          "flex flex-row items-center justify-start absolute inset-0 text-neutral-900 dark:text-neutral-900",
      },
      placeholder: {
        _common:
          "flex flex-row items-center justify-start absolute inset-0 font-normal text-neutral-300 dark:text-neutral-300 pointer-events-none font-element",
      },
    },
  },
}

export {app, OneUI}
